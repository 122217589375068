.popup {
  padding: 10px;
  color: black;
}

.popup-item {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  color: black;
  display: block;
  border: 0;
  background-color: transparent;
  text-align: left;
}

.popup-item:hover {
  color: white;
  background-color: blue;
  cursor: pointer;
}

.popup-item:disabled {
  display: none;
}

.popup-item-x:hover:disabled {
  color: black;
  background-color: transparent;
  cursor: default;
  display: none;
}
