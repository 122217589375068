.svg-div {
  height: 16px;
}

.svg-div-span {
  height: 16px;
  width: 25px;
  display: inline-block;
}

.svg-div-div {
  height: 16px;
  display: inline-block;
  font-size: small;
  vertical-align: top;
}

.svg-div-label {
  display: inline-block;
  font-size: small;
  font-weight: bolder;
  vertical-align: top;
}
.svg-div-text {
  display: inline-block;
  font-size: small;
  font-weight: normal;
  vertical-align: top;
}

.svg {
  height: 16px;
}
