.ui-check {
    display: inline-block;
    vertical-align: top;
    }

.ui-check-label {
    display: block;
    }

.ui-check-span {
    padding-left: 5px;
    }

.ui-check-input {
    color: black;
    font-size: 10px;
    font-weight: 700;
    vertical-align: middle;
    }
