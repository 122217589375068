html, body, .root {
  background-color: rgb(234, 234, 234) !important;
  height: 100%;
}

/*timepicker style*/
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}


/*home page styles*/

.masthead {
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
}

.masthead.segment {
  min-height: 700px;
  padding: 1em 0 !important;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  margin-top: 3em;
  margin-bottom: 0;
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

.footer.segment {
  padding: 5em 0;
}

.secondary.inverted.pointing.menu {
  border: none;
}

/*end home page styles*/

/* navbar styles */

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.fixed.menu {
  background-image: linear-gradient(
          135deg,
          rgb(24, 42, 115) 0%,
          rgb(33, 138, 174) 69%,
          rgb(32, 167, 172) 89%
  ) !important;
}

.ui.main.container,
.main.segment {
  margin-top: 1em;
  width: 90%;
}

.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.ui.menu .item>img:not(.ui) {
  margin-right: 1.5em !important;
}

.ui.menu:not(.vertical) .item>.button {
  margin-left: 0.5em;
}

/*chat comments*/

.ui.comments .comment .comments {
  padding-bottom: 0 !important;
  padding-left: 2em !important;
}

.skill {
  font-weight: bolder;
}

.skill-list-item {
  font-weight: bolder;
  width: 250px;
  margin-left: 0 !important;
  margin-right: 1em;
}

.job-skill-item {
  font-weight: bolder;
  margin-left: 0 !important;
  margin-right: 1em;
  padding: 3px !important;
  cursor: pointer;
}

.skill-in {
  background-color:lightgreen;
}
.skill-ex {
  background-color:lightpink;
}

.skill-n {
  background-color:lightsteelblue;
}

.excluded-skill {
  background-color: lightpink;
  width: 100%;
  font-weight: bolder;
}

.included-skill {
  background-color: lightgreen;
  width: 100%;
  font-weight: bolder;
}

.skill-counts {
  width: 100%;
  font-weight: bolder;
  cursor: pointer;
  color: black;
  padding-top: 2px;
}

.flt-rt
{
  float: right !important;
  float: ;
}

.job-filter-item {
  padding-top: 2px;
}

.job-desc {
  white-space: pre-line;
}

.dimmer
{
  background-color: rgba(255,255,255,.15) !important;
}

.test-width {
  width: 500px !important;
}

.desc-size {
  height: 50px !important;
}

.middle {
  padding-top: 5px !important;
}

.job-dashboard {
  border-top: 1em;
  border-bottom: 1em;
  margin-bottom: 1em;
  background-color:lightsteelblue;
}

.radio {
  padding-right: 10px !important;
}


.inline {
  display: inline-block !important;
  width: 100%;
}
.block {
  display: block !important;
  font-size: 60%;
  width: 100%;
}

.remove-skill {
  width: 500px !important;
  font-weight: bolder;
}

.job-item-title {
  width: 100%;
  background-color: lightgray !important;
}


.job-item-title:hover {
  background-color: gray !important;
}

.job-item-header {
  width: 100%;
  color: black !important;
}
.a:hover {
  color: black !important;
}

.job-filter-header {
  padding: 14px !important;
  background-color: white;
}

.job-list {
  padding-top: 14 !important;
  overflow: auto;
  max-height: 1000;
}

.job-status {
  font-size: 10px;
  font-weight: 700;
}

